exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accepted-insurances-index-jsx": () => import("./../../../src/pages/accepted-insurances/index.jsx" /* webpackChunkName: "component---src-pages-accepted-insurances-index-jsx" */),
  "component---src-pages-administration-index-jsx": () => import("./../../../src/pages/administration/index.jsx" /* webpackChunkName: "component---src-pages-administration-index-jsx" */),
  "component---src-pages-appointment-index-jsx": () => import("./../../../src/pages/appointment/index.jsx" /* webpackChunkName: "component---src-pages-appointment-index-jsx" */),
  "component---src-pages-best-colonoscopy-doctors-nyc-index-jsx": () => import("./../../../src/pages/best-colonoscopy-doctors-nyc/index.jsx" /* webpackChunkName: "component---src-pages-best-colonoscopy-doctors-nyc-index-jsx" */),
  "component---src-pages-best-rated-nyc-gastroenterologists-top-nyc-gi-doctors-index-jsx": () => import("./../../../src/pages/best-rated-nyc-gastroenterologists-top-nyc-gi-doctors/index.jsx" /* webpackChunkName: "component---src-pages-best-rated-nyc-gastroenterologists-top-nyc-gi-doctors-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-book-online-index-jsx": () => import("./../../../src/pages/book-online/index.jsx" /* webpackChunkName: "component---src-pages-book-online-index-jsx" */),
  "component---src-pages-booking-form-thank-you-index-jsx": () => import("./../../../src/pages/booking-form-thank-you/index.jsx" /* webpackChunkName: "component---src-pages-booking-form-thank-you-index-jsx" */),
  "component---src-pages-clinical-research-index-jsx": () => import("./../../../src/pages/clinical-research/index.jsx" /* webpackChunkName: "component---src-pages-clinical-research-index-jsx" */),
  "component---src-pages-conditions-index-jsx": () => import("./../../../src/pages/conditions/index.jsx" /* webpackChunkName: "component---src-pages-conditions-index-jsx" */),
  "component---src-pages-disclosures-index-jsx": () => import("./../../../src/pages/disclosures/index.jsx" /* webpackChunkName: "component---src-pages-disclosures-index-jsx" */),
  "component---src-pages-erber-joins-nyga-index-jsx": () => import("./../../../src/pages/ErberJoinsNYGA/index.jsx" /* webpackChunkName: "component---src-pages-erber-joins-nyga-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-locations-index-jsx": () => import("./../../../src/pages/locations/index.jsx" /* webpackChunkName: "component---src-pages-locations-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-not-found-index-jsx": () => import("./../../../src/pages/not-found/index.jsx" /* webpackChunkName: "component---src-pages-not-found-index-jsx" */),
  "component---src-pages-patient-forms-index-jsx": () => import("./../../../src/pages/patient-forms/index.jsx" /* webpackChunkName: "component---src-pages-patient-forms-index-jsx" */),
  "component---src-pages-patient-reviews-index-jsx": () => import("./../../../src/pages/patient-reviews/index.jsx" /* webpackChunkName: "component---src-pages-patient-reviews-index-jsx" */),
  "component---src-pages-patients-index-jsx": () => import("./../../../src/pages/patients/index.jsx" /* webpackChunkName: "component---src-pages-patients-index-jsx" */),
  "component---src-pages-pay-bill-index-jsx": () => import("./../../../src/pages/pay-bill/index.jsx" /* webpackChunkName: "component---src-pages-pay-bill-index-jsx" */),
  "component---src-pages-portal-index-jsx": () => import("./../../../src/pages/portal/index.jsx" /* webpackChunkName: "component---src-pages-portal-index-jsx" */),
  "component---src-pages-prepare-for-procedure-index-jsx": () => import("./../../../src/pages/prepare-for-procedure/index.jsx" /* webpackChunkName: "component---src-pages-prepare-for-procedure-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-providers-index-jsx": () => import("./../../../src/pages/providers/index.jsx" /* webpackChunkName: "component---src-pages-providers-index-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-surgery-centers-index-jsx": () => import("./../../../src/pages/surgery-centers/index.jsx" /* webpackChunkName: "component---src-pages-surgery-centers-index-jsx" */),
  "component---src-pages-team-index-jsx": () => import("./../../../src/pages/team/index.jsx" /* webpackChunkName: "component---src-pages-team-index-jsx" */),
  "component---src-pages-terms-conditions-index-jsx": () => import("./../../../src/pages/terms-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-index-jsx" */),
  "component---src-pages-webinars-index-jsx": () => import("./../../../src/pages/webinars/index.jsx" /* webpackChunkName: "component---src-pages-webinars-index-jsx" */),
  "component---src-pages-westside-gastro-joins-nyga-index-jsx": () => import("./../../../src/pages/WestsideGastro-Joins-NYGA/index.jsx" /* webpackChunkName: "component---src-pages-westside-gastro-joins-nyga-index-jsx" */),
  "component---src-pages-why-nyga-index-jsx": () => import("./../../../src/pages/why-nyga/index.jsx" /* webpackChunkName: "component---src-pages-why-nyga-index-jsx" */),
  "component---src-templates-administrator-jsx": () => import("./../../../src/templates/Administrator.jsx" /* webpackChunkName: "component---src-templates-administrator-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-center-jsx": () => import("./../../../src/templates/Center.jsx" /* webpackChunkName: "component---src-templates-center-jsx" */),
  "component---src-templates-condition-jsx": () => import("./../../../src/templates/Condition.jsx" /* webpackChunkName: "component---src-templates-condition-jsx" */),
  "component---src-templates-location-jsx": () => import("./../../../src/templates/Location.jsx" /* webpackChunkName: "component---src-templates-location-jsx" */),
  "component---src-templates-news-post-jsx": () => import("./../../../src/templates/NewsPost.jsx" /* webpackChunkName: "component---src-templates-news-post-jsx" */),
  "component---src-templates-provider-jsx": () => import("./../../../src/templates/Provider.jsx" /* webpackChunkName: "component---src-templates-provider-jsx" */),
  "component---src-templates-service-jsx": () => import("./../../../src/templates/Service.jsx" /* webpackChunkName: "component---src-templates-service-jsx" */),
  "component---src-templates-speciality-jsx": () => import("./../../../src/templates/Speciality.jsx" /* webpackChunkName: "component---src-templates-speciality-jsx" */),
  "component---src-templates-webinar-jsx": () => import("./../../../src/templates/Webinar.jsx" /* webpackChunkName: "component---src-templates-webinar-jsx" */)
}

